export const ZIPCODES_ENKHUIZEN = [1600, 1601, 1602];
export const ZIPCODES_STEDE_BROEC = [1610, 1611, 1613, 1614];
export const SEND_COST_STANDARD = 3.95;
export const SEND_COST_STANDARD_LANDELIJK = 8.95;
export const SEND_COST_DISCOUNT_LANDELIJK = 5.95;
export const FREE_DELIVERY_FROM_PRICE_STANDARD = 35;
export const DISCOUNT_DELIVERY_FROM_PRICE_LANDELIJK = 100;
export const ZIPCODES_WEST_FRIESLAND = [
  1606, 1616, 1607, 1617, 1608, 1696, 1609, 1697, 1620, 1624, 1689, 1621, 1625,
  1695, 1622, 1627, 1623, 1628, 1631, 1642, 1648, 1633, 1643, 1652, 1634, 1711,
  1641, 1647, 1713, 1619, 1658, 1676, 1682, 1686, 1692, 1654, 1670, 1678, 1683,
  1687, 1693, 1655, 1671, 1679, 1684, 1688, 1657, 1674, 1681, 1685, 1691, 1661,
  1716, 1662, 1718, 1663, 1719, 1715,
];
export const SEND_COST_WEST_FRIESLAND = 4.95;
export const FREE_DELIVERY_FROM_PRICE_WEST_FRIESLAND = 50;

export const CURRENCY = "€ ";
export const CURRENCY_CODE = "EUR";
export const DELIVERY_OPTIONS = {
  PICKUP: "pickup",
  SEND: "send",
};

export const PAYMENT_METHODS = {
  CREDITCARD: "creditcard",
  IDEAL: "ideal",
  BANCONTACT: "bancontact",
};

export const ADDRESS_TYPES = {
  DELIVERY: "DELIVERY",
  INVOICE: "INVOICE",
};
export const PICKUP_TIME_FROM = "13:00";
export const PICKUP_TIME_UNTIL = "15:00";
export const PICKUP_TIME_FROM_SATURDAY = "11:00";
export const PICKUP_TIME_UNTIL_SATURDAY = "15:00";
export const DELIVERY_TIME_FROM = "14:00";
export const DELIVERY_TIME_FROM_LANDELIJK = "17:00";
export const DELIVERY_TIME_UNTIL = "18:00";
export const DELIVERY_TIME_UNTIL_LANDELIJK = "22:00";
export const DELIVERY_DATE_WARNING_DAYS = 28;
export const SATURDAY_PICKUP_MIN_ORDER_AMOUNT = 50;
